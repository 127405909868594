import React from "react";
import Layout from "../../components/Layout";

// eslint-disable-next-line
export default () => (
  <Layout>
    <section className="section">
      <div className="container">
        <div className="content">
          <h1>Thank you!</h1>
          <p>Thank you for reaching out to us! Your submission has been successfully received. Our team will review your inquiry and get back to you as soon as possible. We appreciate your interest and look forward to assisting you.</p>
        </div>
      </div>
    </section>
  </Layout>
);
